/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import firebase from "firebase/app";
import { graphql, navigate, useStaticQuery } from "gatsby";
import {
  AccountSlide,
  Button,
  Go,
  JourneySlider,
  RegisterSlide,
  StripeSlide
} from "~components";
import { useApp, useAuth, useEmail, useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor, validateEmail } from "~utils/helpers";

import { ReactComponent as ConfidenceIcon } from "~assets/icons/confidence-icon.svg";
import { ReactComponent as LinkedInIcon } from "~assets/icons/linkedin.svg";
import { ReactComponent as InstagramIcon } from "~assets/icons/instagram.svg";
import { ReactComponent as StackIcon } from "~assets/icons/stack-icon.svg";
import { ReactComponent as TokenIcon } from "~assets/icons/token-icon.svg";

import placeholderToken from "~assets/images/token.png";

const Register = () => {
  const { sanityRegisterSlides } = useStaticQuery(graphql`
    query Register {
      sanityRegisterSlides {
        slide1Heading

        slide2Heading
        slide2SectorsHeading
        slide2GainsHeading
        slide2JourneyHeading
        slide2JourneyItems
        slide2AboutHeading
        slide2SocialHeading

        slide3Heading
        slide3PlanHeading
        slide3FreeTitle
        slide3FreeBody
        slide3PaidTitle
        slide3PaidBody
        slide3PaymentHeading
        slide3PaymentBody
        slide3InductionHeading
        slide3InductionBody
      }
    }
  `);

  const {
    slide1Heading,
    //
    slide2Heading,
    slide2SectorsHeading,
    slide2GainsHeading,
    slide2JourneyHeading,
    slide2JourneyItems,
    slide2AboutHeading,
    slide2SocialHeading,
    //
    slide3Heading,
    slide3PlanHeading,
    slide3FreeTitle,
    slide3FreeBody,
    slide3PaidTitle,
    slide3PaidBody,
    slide3PaymentHeading,
    slide3PaymentBody,
    slide3InductionHeading,
    slide3InductionBody
  } = sanityRegisterSlides;

  // ==========================================================================
  // context / ref / state

  const { klaviyoSubscribe } = useEmail();

  const { registerActive, setRegisterActive } = useApp();
  const { loadAccountById } = useAuth();
  const { mail } = useEmail();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  const [userAccountId, setUserAccountId] = useState(null);

  const [form, setForm] = useState({
    goals: [],
    journey: 0,
    sectors: []
  });
  const [status, setStatus] = useState({
    creating: false,
    created: false,
    error: null
  });

  // ==========================================================================
  // variables

  const allGoals = [
    `Improve my confidence`,
    `Learn new skills`,
    `Develop my product`,
    `Join a community`
  ];

  const allSectors = [
    `Fashion & Lifestyle`,
    `Agriculture`,
    `Energy`,
    `Health & Wellness`,
    `Marketing & Advertising`,
    `Architecture`,
    `Design`,
    `Food & Beverage`,
    `Travel`
  ];

  let stripeValid = false;

  if (typeof window !== `undefined`) {
    stripeValid = !window.location.href.includes(`/profile`) && registerActive;
  }

  // ==========================================================================
  // methods

  const notEmpty = (string) => typeof string !== `undefined` && string !== ``;

  const reset = () => {
    setForm({});
    setActiveStep(0);
    setUserAccountId(null);
  };

  const stepComplete = (index) => {
    let complete = false;

    switch (index) {
      case 0:
        complete =
          notEmpty(form?.firstName) &&
          notEmpty(form?.lastName) &&
          validateEmail(form?.email) &&
          notEmpty(form?.password) &&
          notEmpty(form?.passwordConfirm) &&
          form?.password === form?.passwordConfirm;

        break;

      case 1:
        complete =
          notEmpty(form?.jobTitle) &&
          notEmpty(form?.companyName) &&
          notEmpty(form?.companyWebsite);

        break;

      case 2:
        complete = notEmpty(form?.starterPlan);
        break;

      default:
        complete = false;
        break;
    }

    return complete;
  };

  const prevSlide = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const nextSlide = () => {
    setActiveStep(activeStep + 1);
  };

  const registrationComplete = () => {
    navigate(`/thankyou/`);

    setTimeout(() => {
      setRegisterActive(false);
    }, 500);

    setTimeout(() => {
      reset();
    }, 1000);
  };

  const toggleGoal = (goal) => {
    const goals = JSON.parse(JSON.stringify(form?.goals || []));

    const existingIndex = goals?.indexOf(goal);

    if (existingIndex !== -1) {
      goals.splice(existingIndex, 1);
    } else {
      goals.push(goal);
    }

    setForm({
      ...form,
      goals
    });
  };

  const toggleSector = (sector) => {
    const sectors = JSON.parse(JSON.stringify(form?.sectors || []));

    const existingIndex = sectors?.indexOf(sector);

    if (existingIndex !== -1) {
      sectors.splice(existingIndex, 1);
    } else {
      sectors.push(sector);
    }

    setForm({
      ...form,
      sectors
    });
  };

  const onChange = (e) => {
    setForm({
      ...form,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  useEffect(() => {
    let trial = 0;

    if (form?.starterPlan?.toLowerCase() !== `free`) {
      switch (form?.referral_code?.toUpperCase()?.trim()) {
        case `LUCY_SUBSCRIBE6`:
          trial = 180;
          break;

        case `LUCY_SUBSCRIBE_ANNUAL`:
          trial = 360;
          break;

        default:
          break;
      }
    }

    if (trial !== form?.trial) {
      setForm({
        ...form,
        trial
      });
    }
  }, [form]);

  const onJourneyChange = (journey) => {
    setForm({
      ...form,
      journey
    });
  };

  const createUser = async () => {
    if (!firebase) {
      // eslint-disable-next-line no-console
      console.warn(`Firebase undefined`);
      return;
    }

    if (!form?.email || !form?.password) {
      // eslint-disable-next-line no-console
      console.warn(`Form variables unset`);
      return;
    }

    const db = firebase.firestore();

    try {
      //
      // 1. Create new data under the 'users' collection strictly for authentication
      //    Google tightly controls this data type
      const newAuthUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(form.email, form.password);

      //
      // 1a. Run some fire/forget async mail tasks
      if (newAuthUser) {
        // Temporary disable to welcome emails
        // try {
        //   mail({
        //     to: form.email,
        //     templateID: `welcome`,
        //     replacements: {
        //       name: `${form.firstName} ${form.lastName}`
        //     },
        //     subject: `Welcome to The WIP`
        //   });
        // } catch (e) {
        //   console.error(`Failed to send welcome email: `, e);
        // }

        try {
          klaviyoSubscribe({
            listId: `RS79bR`,
            email: form.email
          });
        } catch (e) {
          console.error(`Failed to subscribe user to Klaviyo app users: `, e);
        }

        if (form?.newsletterAccept === true) {
          try {
            klaviyoSubscribe({
              listId: `XkpcJQ`,
              email: form.email
            });
          } catch (e) {
            console.error(
              `Failed to subscribe user to Klaviyo newsletter: `,
              e
            );
          }
        }
      }

      //
      // 2. Create new data under the 'accounts' collection for custom supplementary data.
      const safeData = (({ password, passwordConfirm, starterPlan, ...f }) =>
        f)(form);

      const accountData = {
        uid: newAuthUser.user.uid,
        ...safeData,
        paymentMethodId: ``,
        purchasedArticles: ``,
        purchasedEvents: ``,
        tokens: 0
      };

      const newAccount = await db.collection(`accounts`).add(accountData);

      setUserAccountId(newAccount.id);

      //
      // 3. Create a Stripe customer for this account
      const getStripeCustomer = firebase
        .functions()
        .httpsCallable(`getStripeCustomer`);
      const stripeCustomer = await getStripeCustomer({
        accountId: newAccount.id,
        name: `${safeData.firstName} ${safeData.lastName}`,
        email: form.email
      });

      if (stripeCustomer?.data) {
        loadAccountById(newAccount.id);
      }

      setStatus({
        creating: false,
        created: true,
        error: null
      });

      //
      // 4. Conditional routing based on plan type
      if (form?.starterPlan?.toLowerCase() === `free`) {
        registrationComplete();
      } else {
        setActiveStep(4);
      }
    } catch (error) {
      setStatus({
        creating: true,
        created: false,
        error: error.message
      });

      // eslint-disable-next-line no-console
      console.error(`Account creation failed: `, error.message);
    }
  };

  // ==========================================================================
  // render

  return (
    <div
      css={[
        css`
          // ${colourTransitionCSS}, 0.5s ease opacity, 0.5s ease transform;
          ${colourTransitionCSS};

          opacity: ${registerActive ? `1` : `0`};
          pointer-events: ${registerActive ? `auto` : `none`};

          background: ${lowImpactMode
            ? colours.background
            : getColor(`green-light`)};
          color: ${lowImpactMode ? colours.foreground : getColor(`green-dark`)};

          padding-top: 56px;

          ${MEDIA_QUERIES?.desktop} {
            padding-top: 90px;
          }
        `,
        tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-30`
      ]}
    >
      <div
        css={[
          css`
            transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) opacity,
              0.5s cubic-bezier(0.215, 0.61, 0.355, 1) transform;
            transition-delay: 100ms;

            opacity: ${registerActive ? `1` : `0`};
            transform: translate3d(0, ${registerActive ? `0` : `-2vw`}, 0);
          `,
          tw`w-full h-full relative flex`
        ]}
      >
        <RegisterSlide
          activeStep={activeStep}
          description={slide1Heading}
          stepIndex="0"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (stepComplete(0)) {
                nextSlide();
              }
            }}
          >
            <label htmlFor="register_pronouns" css={[tw`relative block mb-8`]}>
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Preferred pronouns
              </span>

              <input
                id="register_pronouns"
                name="pronouns"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                placeholder="Please enter your preferred pronouns (optional)"
                type="text"
              />
            </label>

            <label htmlFor="register_firstName" css={[tw`relative block mb-8`]}>
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Preferred name
              </span>

              <input
                id="register_firstName"
                name="firstName"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                placeholder="Please enter your preferred name"
                required
                type="text"
              />
            </label>

            <label htmlFor="register_lastName" css={[tw`relative block mb-8`]}>
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Surname
              </span>

              <input
                id="register_lastName"
                name="lastName"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                placeholder="Please enter your surname"
                required
                type="text"
              />
            </label>

            <label htmlFor="register_email" css={[tw`relative block mb-8`]}>
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Email
              </span>

              <input
                id="register_email"
                name="email"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                placeholder="Please enter your email"
                required
                type="email"
              />
            </label>

            <label htmlFor="register_password" css={[tw`relative block mb-8`]}>
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Password (min. 8 characters, at least one numeric and one
                capital)
              </span>

              <input
                id="register_password"
                name="password"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                placeholder="********"
                required
                type="password"
              />
            </label>

            <label
              htmlFor="register_passwordConfirm"
              css={[tw`relative block mb-3 md:mb-8`]}
            >
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Confirm Password (min. 8 characters, at least one numeric and
                one capital)
              </span>

              <input
                id="register_passwordConfirm"
                name="passwordConfirm"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                placeholder="********"
                required
                type="password"
              />
            </label>

            <label
              htmlFor="register_newsletterAccept"
              css={[tw`relative flex pt-3 mb-4`]}
            >
              <input
                id="register_newsletterAccept"
                name="newsletterAccept"
                css={[
                  css`
                    margin-top: 0.2vw;
                  `
                ]}
                type="checkbox"
                defaultChecked
              />

              <span
                css={[
                  tw`block ml-4 mb-2 font-body italic text-b2 md:text-b2-md`
                ]}
              >
                Subscribe to our newsletter for news and updates
              </span>
            </label>

            <p css={[tw`font-body text-b2 md:text-b2-md italic`]}>
              <span>Links to </span>

              <Go
                to="/privacy-policy"
                _css={[tw`underline`]}
                onClick={() => setRegisterActive(false)}
              >
                privacy
              </Go>

              <span> and </span>

              <Go
                to="/terms-of-service"
                _css={[tw`underline`]}
                onClick={() => setRegisterActive(false)}
              >
                terms of service.
              </Go>
            </p>

            {/* // */}

            <div css={[tw`w-full relative flex mt-6 md:mt-12`]}>
              <Button
                buttonType="submit"
                _css={[
                  css`
                    //
                  `,
                  tw`h-10 md:h-auto px-6`
                ]}
                color={colours.secondary}
                text="Next"
              />
            </div>
          </form>
        </RegisterSlide>

        {/* // */}

        <RegisterSlide
          activeStep={activeStep}
          description={slide2Heading}
          stepIndex="1"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (stepComplete(1)) {
                nextSlide();
              }
            }}
          >
            <label htmlFor="register_jobTitle" css={[tw`relative block mb-8`]}>
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Job title
              </span>

              <input
                id="register_jobTitle"
                name="jobTitle"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                placeholder="Please enter your current job title"
                type="text"
              />
            </label>

            <label
              htmlFor="register_companyName"
              css={[tw`relative block mb-8`]}
            >
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Company name
              </span>

              <input
                id="register_companyName"
                name="companyName"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                placeholder="Please enter the name of your company"
                type="text"
              />
            </label>

            {/* // */}

            <div css={[tw`relative block mb-8`]}>
              <p css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}>
                {slide2SectorsHeading}
              </p>

              <div css={[tw`relative flex flex-wrap`]}>
                {allSectors.map((sector) => {
                  const background = lowImpactMode
                    ? colours?.foreground
                    : colours?.background;
                  const foreground = lowImpactMode
                    ? colours?.background
                    : colours?.foreground;

                  return (
                    <div key={sector}>
                      <Button
                        _css={[
                          css`
                            transition: 0.3s ease opacity;
                            border: 1px solid ${background} !important;

                            ${MEDIA_QUERIES.hoverable} {
                              &:hover {
                                opacity: 0.3;
                              }
                            }
                          `,
                          tw`h-10 md:h-auto mr-4 mb-4 px-4`
                        ]}
                        color={
                          form?.sectors?.includes(sector)
                            ? background
                            : foreground
                        }
                        onClick={() => {
                          toggleSector(sector);
                        }}
                        text={sector}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {/* // */}

            <JourneySlider
              heading={slide2JourneyHeading}
              items={slide2JourneyItems}
              onChange={onJourneyChange}
            />

            {/* // */}

            <div
              css={[
                css`
                  //
                `,
                tw`relative block mb-8`
              ]}
            >
              <p css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}>
                {slide2GainsHeading}
              </p>

              <div
                css={[
                  css`
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    ${MEDIA_QUERIES.desktop} {
                      width: 120%;
                      flex-wrap: nowrap;
                      justify-content: flex-start;
                    }
                  `,
                  tw`relative flex`
                ]}
              >
                {allGoals.map((goal) => {
                  const background = lowImpactMode
                    ? colours?.foreground
                    : colours?.background;
                  const foreground = lowImpactMode
                    ? colours?.background
                    : colours?.foreground;

                  const active = form?.goals?.includes(goal);

                  const iconCSS = [
                    css`
                      transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) fill;
                    `,
                    tw`w-3/5`
                  ];

                  let icon;

                  switch (goal) {
                    case `Improve my confidence`:
                      icon = (
                        <ConfidenceIcon
                          css={iconCSS}
                          fill={active ? foreground : background}
                        />
                      );

                      break;

                    case `Learn new skills`:
                      icon = (
                        <TokenIcon
                          css={iconCSS}
                          fill={active ? foreground : background}
                        />
                      );

                      break;

                    case `Develop my product`:
                      icon = (
                        <StackIcon
                          css={iconCSS}
                          fill={active ? foreground : background}
                        />
                      );

                      break;

                    case `Join a community`:
                      icon = (
                        <StackIcon
                          css={iconCSS}
                          fill={active ? foreground : background}
                        />
                      );

                      break;

                    default:
                      break;
                  }

                  //

                  return (
                    <div
                      key={`goal-${goal}`}
                      css={[
                        css`
                          width: calc(50% - 0.5rem);
                          margin-bottom: 1rem;
                          height: auto;
                          color: ${active ? foreground : background};

                          ${MEDIA_QUERIES.desktop} {
                            width: 25%;
                            height: 12.5vw;
                            margin-top: 1vw;
                            margin-right: 2vw;
                            margin-bottom: auto;
                          }
                        `,
                        tw`relative block`
                      ]}
                    >
                      <button
                        type="button"
                        css={[
                          css`
                            transition: 0.3s ease opacity;

                            padding: 5%;
                            background: ${active ? background : foreground};
                            border: 1px solid ${background};

                            ${MEDIA_QUERIES.hoverable} {
                              &:hover {
                                opacity: 0.3 !important;
                              }
                            }
                          `,
                          tw`w-full h-full relative block overflow-hidden rounded-xl`
                        ]}
                        onClick={() => {
                          toggleGoal(goal);
                        }}
                      >
                        <div
                          css={[
                            tw`w-full h-full relative flex flex-wrap flex-col items-center justify-center`
                          ]}
                        >
                          {icon}

                          <p
                            css={[
                              css`
                                height: 3rem;
                                margin-top: 0.75rem;
                                margin-right: 0.5rem;
                                line-height: 90% !important;

                                ${MEDIA_QUERIES.hoverable} {
                                  height: 2vw;
                                  margin-top: 1vw;
                                  margin-right: 0;
                                }
                              `,
                              tw`w-3/4 md:w-full mx-auto text-b2 md:text-b2-md font-body`
                            ]}
                          >
                            {goal}
                          </p>
                        </div>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* // */}

            <label htmlFor="register_about" css={[tw`relative block mb-8`]}>
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                {slide2AboutHeading}
              </span>

              <textarea
                id="register_about"
                name="about"
                css={[
                  tw`w-full h-32 relative block mb-4 pt-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                placeholder="Please write a short bio to introduce yourself to other WIP members"
                type="text"
              />
            </label>

            {/* // */}

            <label
              htmlFor="register_companyWebsite"
              css={[tw`relative block mb-8`]}
            >
              <span
                css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
              >
                Company website
              </span>

              <input
                id="register_companyWebsite"
                name="companyWebsite"
                css={[
                  tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                ]}
                onChange={onChange}
                placeholder="Link to your website"
                type="text"
              />
            </label>

            {/* // */}

            <div css={[tw`relative block mb-8`]}>
              <p css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}>
                {slide2SocialHeading}
              </p>

              <label
                htmlFor="register_linkedin"
                css={[tw`relative flex items-center justify-between mb-5`]}
              >
                <div
                  css={[
                    css`
                      width: 1.5rem;
                      height: 1.5rem;

                      ${MEDIA_QUERIES.desktop} {
                        width: 3vw;
                        height: 3vw;
                      }
                    `,
                    tw`rounded-full overflow-hidden flex items-center justify-center bg-green-light`
                  ]}
                >
                  <LinkedInIcon css={[tw`w-full h-full`]} fill="#305247" />
                </div>

                <input
                  id="register_linkedin"
                  name="linkedin"
                  css={[
                    css`
                      width: calc(100% - 2rem);

                      ${MEDIA_QUERIES.desktop} {
                        width: calc(100% - 4vw);
                      }
                    `,
                    tw`h-12 relative block px-4 rounded-md font-body text-b2 md:text-b2-md`
                  ]}
                  onChange={onChange}
                  placeholder="Link to Linkedin"
                  type="text"
                />
              </label>

              <label
                htmlFor="register_instagram"
                css={[tw`relative flex items-center justify-between mb-5`]}
              >
                <div
                  css={[
                    css`
                      width: 1.5rem;
                      height: 1.5rem;

                      ${MEDIA_QUERIES.desktop} {
                        width: 3vw;
                        height: 3vw;
                      }
                    `,
                    tw`rounded-full flex items-center justify-center bg-green-dark`
                  ]}
                >
                  <InstagramIcon css={[tw`w-3/5`]} fill="#DEEFCB" />
                </div>

                <input
                  id="register_instagram"
                  name="instagram"
                  css={[
                    css`
                      width: calc(100% - 2rem);

                      ${MEDIA_QUERIES.desktop} {
                        width: calc(100% - 4vw);
                      }
                    `,
                    tw`h-12 relative block px-4 rounded-md font-body text-b2 md:text-b2-md`
                  ]}
                  onChange={onChange}
                  placeholder="Link to Instagram"
                  type="text"
                />
              </label>
            </div>

            <div css={[tw`w-full relative flex mt-6 md:mt-12`]}>
              <Button
                buttonType="button"
                _css={[
                  css`
                    //
                  `,
                  tw`mr-4 px-6`
                ]}
                color={colours.secondary}
                onClick={() => {
                  prevSlide();
                }}
                text="Back"
              />

              <Button
                buttonType="submit"
                _css={[
                  css`
                    //
                  `,
                  tw`h-10 md:h-auto px-6`
                ]}
                color={colours.secondary}
                text="Next"
              />
            </div>
          </form>
        </RegisterSlide>

        {/* // */}

        <RegisterSlide
          activeStep={activeStep}
          description={slide3Heading}
          stepIndex="2"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (form?.password !== form?.passwordConfirm) {
                // todo: move to a thing
                return;
              }

              if (stepComplete(2)) {
                nextSlide();

                nextSlide();

                setStatus({
                  creating: true,
                  created: false,
                  error: null
                });

                setTimeout(() => {
                  createUser();
                }, 500);
              }
            }}
          >
            <p css={[tw`mb-8 text-b2 md:text-b2-md font-body italic`]}>
              {slide3PlanHeading}
            </p>

            <button
              type="button"
              css={[
                css`
                  transition: 0.3s ease opacity;

                  ${MEDIA_QUERIES.hoverable} {
                    &:hover {
                      opacity: 0.3 !important;
                    }
                  }

                  ${lowImpactMode
                    ? `
                    background: ${
                      form?.starterPlan === `free`
                        ? colours.foreground
                        : colours.background
                    };
                    color: ${
                      form?.starterPlan === `free`
                        ? colours.background
                        : colours.foreground
                    };
                    `
                    : `
                    background: ${
                      form?.starterPlan === `free`
                        ? colours.background
                        : colours.foreground
                    };
                    color: ${
                      form?.starterPlan === `free`
                        ? colours.foreground
                        : colours.background
                    };
                  `}
                `,
                tw`w-full relative block mb-4 pt-4 pr-10 pb-4 border rounded-xl`
              ]}
              onClick={() => setForm({ ...form, starterPlan: `free` })}
            >
              <div css={[tw`w-full relative flex`]}>
                <div css={[tw`w-1/6 relative flex items-start justify-center`]}>
                  <img
                    css={[tw`w-2/3 relative block`]}
                    src={placeholderToken}
                    alt="Token placeholder"
                  />
                </div>

                <div css={[tw`w-5/6 relative text-left`]}>
                  <header css={[tw`w-full relative mb-4 pb-4 border-b`]}>
                    <h3
                      css={[
                        tw`w-full relative font-body text-h4 md:text-h4-md`
                      ]}
                    >
                      {slide3FreeTitle}
                    </h3>
                  </header>

                  <p
                    css={[
                      tw`w-full relative whitespace-pre-wrap mb-1 font-body text-b2 md:text-b2-md`
                    ]}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: slide3FreeBody }}
                    />
                  </p>
                </div>
              </div>
            </button>

            <button
              type="button"
              css={[
                css`
                  transition: 0.3s ease opacity;

                  ${MEDIA_QUERIES.hoverable} {
                    &:hover {
                      opacity: 0.3 !important;
                    }
                  }

                  ${lowImpactMode
                    ? `
                    background: ${
                      form?.starterPlan === `premium`
                        ? colours.foreground
                        : colours.background
                    };
                    color: ${
                      form?.starterPlan === `premium`
                        ? colours.background
                        : colours.foreground
                    };
                    `
                    : `
                    background: ${
                      form?.starterPlan === `premium`
                        ? colours.background
                        : colours.foreground
                    };
                    color: ${
                      form?.starterPlan === `premium`
                        ? colours.foreground
                        : colours.background
                    };
                    `}
                `,
                tw`w-full relative block mb-4 pt-4 pr-10 pb-4 border rounded-xl`
              ]}
              onClick={() => setForm({ ...form, starterPlan: `premium` })}
            >
              <div css={[tw`w-full relative flex`]}>
                <div css={[tw`w-1/6 relative flex items-start justify-center`]}>
                  <img
                    css={[tw`w-2/3 relative block`]}
                    src={placeholderToken}
                    alt="Token placeholder"
                  />
                </div>

                <div css={[tw`w-5/6 relative text-left`]}>
                  <header css={[tw`w-full relative mb-4 pb-4 border-b`]}>
                    <h3
                      css={[
                        tw`w-full relative font-body text-h4 md:text-h4-md`
                      ]}
                    >
                      {slide3PaidTitle}
                    </h3>
                  </header>

                  <p
                    css={[
                      tw`w-full relative whitespace-pre-wrap mb-1 font-body text-b2 md:text-b2-md`
                    ]}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: slide3PaidBody }}
                    />
                  </p>
                </div>
              </div>
            </button>

            <div css={[tw`w-full relative mt-8`]}>
              <label
                htmlFor="register_referral_code"
                css={[tw`relative block mb-8`]}
              >
                <span
                  css={[tw`block mb-2 font-body italic text-b2 md:text-b2-md`]}
                >
                  Referral code
                </span>

                <input
                  id="register_referral_code"
                  name="referral_code"
                  css={[
                    tw`w-full h-12 relative block mb-4 px-4 rounded-md font-body text-b2 md:text-b2-md`
                  ]}
                  onChange={onChange}
                  placeholder="Referral code (optional)"
                  type="text"
                />
              </label>
            </div>

            <div css={[tw`w-full relative mt-8`]}>
              <p css={[tw`mb-4 font-body text-b2 md:text-b2-md italic`]}>
                {slide3PaymentHeading}
              </p>
              <p css={[tw`font-body text-b2 md:text-b2-md`]}>
                {slide3PaymentBody}
              </p>
            </div>

            <div css={[tw`w-full relative mt-8`]}>
              <p css={[tw`mb-4 font-body text-b2 md:text-b2-md italic`]}>
                {slide3InductionHeading}
              </p>
              <p css={[tw`font-body text-b2 md:text-b2-md`]}>
                {slide3InductionBody}
              </p>
            </div>

            <div css={[tw`w-full relative flex mt-6 md:mt-12`]}>
              <Button
                buttonType="button"
                _css={[
                  css`
                    //
                  `,
                  tw`mr-4 px-6`
                ]}
                color={colours.secondary}
                onClick={() => {
                  prevSlide();
                }}
                text="Back"
              />

              <Button
                buttonType="submit"
                _css={[
                  css`
                    //
                  `,
                  tw`h-10 md:h-auto px-6`
                ]}
                color={colours.secondary}
                text="Next"
              />
            </div>
          </form>
        </RegisterSlide>

        {/* // */}

        <AccountSlide activeStep={activeStep} stepIndex="3" status={status} />

        {/* // */}

        {stripeValid && (
          <StripeSlide
            accountId={userAccountId}
            activeStep={activeStep}
            onComplete={() => {
              registrationComplete();
            }}
            onStepBack={prevSlide}
            stepIndex="4"
            trial={typeof form?.trial !== `undefined` ? form.trial : 0}
          />
        )}
      </div>
    </div>
  );
};

export default Register;
